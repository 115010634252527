import countryList from "./countryList";
import telCountryList from "./telCountryList";
import stateList from "./stateList";
import adultPattern from "./adultPattern";
import ASIPattern from "./ASIPattern";
import { Constants,ASI_OUTPUT_DOCUMENT } from "../api";
import {steps1, steps2,steps3,steps4 } from "./tours"

// import assessmentPattern from "./assessmentTest"

const initialState = {
  // authParam: { login: "mkontsevoy@aol.com", password: "HelloHello11" },
  //authParam: { login: "hafnow@gmail.com", password: "HelloHello11" },
  authParam: { login: "", password: "" },
  isLoggedIn: false,
  isRemoteSession: false,
  addTextRemoteSession: "",
  setupOrg: {
    organization: "",
    contact_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
    email: "",
    country: "United States (+1)",
    phone: "",
    foto: "",
    foto_trg: "",
    referral: "",
    referral_parent: "",
    sms_note: 0,
    changed: false,
  },
//   setupUser: {
//     id: 0,
//     CompanyId: 0,
//     firstname: "MKO",
//     lastname: "LOI",
//     organization: "MRT",
//     email: "hafnow1@gmail.com",
//     email_onblur: "hafnow1@gmail.com",
//     email_repeat: "hafnow1@gmail.com",
//     email_repeat_onblur: "hafnow1@gmail.com",
//     password: "HelloHello11",
//     confirm: "HelloHello11",
//     created: "",
//     role: "",
//     status: "",
//     attempts: 0,
//     showgettingstarted: 0,
//     showtour: 0,
//     verifycode: "",
//     version_word: "",
//     degree: "",
//     prof_credential: ""
// },

  setupUser: {
    id: 0,
    CompanyId: 0,
    firstname: "",
    lastname: "",
    organization: "",
    // country:'United States',
    email: "",
    email_onblur: "",
    email_repeat: "",
    email_repeat_onblur: "",
    password: "",
    confirm: "",
    created: "",
    role: "",
    status: "",
    attempts: 0,
    showgettingstarted: 0,
    showtour: 0,
    verifycode: "",
    version_word: "",
    degree: "",
    prof_credential: "",
  },

  newUser: {
    id: 0,
    CompanyId: 0,
    firstname: "",
    lastname: "",
    // organization: "",
    // country:'United States',
    email: "",
    email_onblur: "",
    email_repeat: "",
    email_repeat_onblur: "",
    password: "",
    confirm: "",
    created: "",
    role: "Counselor",
    status: "",
    attempts: 0,
    showgettingstarted: 0,
    showtour: 0,
    verifycode: "",
    version_word: "Desktop version",
    degree: "",
    prof_credential: "",
  },
  newCredit: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    total_sum: 0,
    course: Constants.COURSE,
    incoming: 0,
    outcoming: 0,
    credit_date: "",
    subscription_start_date: "",
    subscription_end_date: "",
    subscription_status: "",
    type_system: "",
    stripe_price_id: "",
    cardholder: "",
    card_email: "",
    product_name: "Credit",
    save_card_details: false,
    save_card_details_changed: false,
    recurring_payment: false,
    recurring_payment_changed: false,
    products: [
      {
        id: 1,
        name: "Credit",
        course: Constants.COURSE,
        incoming: 1,
        total_sum: 0,
        quantity: 0,
      },
      {
        id: 2,
        name: "Bundle20",
        course: 200,
        incoming: 21,
        total_sum: 0,
        quantity: 0,
      },
      {
        id: 3,
        name: "Bundle60",
        course: 600,
        incoming: 64,
        total_sum: 0,
        quantity: 0,
      },
      {
        id: 4,
        name: "Bundle80",
        course: 800,
        incoming: 86,
        total_sum: 0,
        quantity: 0,
      },
      {
        id: 5,
        name: "Bundle100",
        course: 1000,
        incoming: 120,
        total_sum: 0,
        quantity: 0,
      },
    ],
    // products: [
    //   { id: 1, name: "Credit", price: Constants.COURSE, quantity:0  },
    //   { id: 2, name: "Bundle10",price: 200, quantity:11 },
    //   { id: 3, name: "Bundle30",price: 600, quantity:34 },
    //   { id: 4, name: "Bundle40", price: 800, quantity:46 },
    //   { id: 5, name: "Bundle50",price: 1000, quantity:60 }
    // ],
    // statusProc: "failure",
    /*
    address_city: "",
    address_country_code: "",
    address_line1: "",
    address_postal_code: "",
    address_recipient_name: "",
    address_state: "",
    paypal_email: "",
    payerID: "",
    paymentID: "",
    paymentToken: "",
    returnUrl: "",
    description: "",
*/
    description: "",
    status: "",
    payment_json: {},
    // allow_input_referral: false,
    referral_parent: "",
  },
  newMessage: {
    id: 0,
    CompanyId: 0,
    FromUserId: 0,
    ToUserId: 0,
    msg: "",
    status: "",
    msg_date: "",
  },

  newImpression: {
    id: 0,
    CompanyId: 0,
    shortname: "",
    checkbox_options: "",
    problem: 0,
  },

  countries: countryList,
  telCountries: telCountryList,

  states: stateList,
  roles: [
    { id: 1, role: "Administrator" },
    { id: 2, role: "Counselor" },
    { id: 3, role: "Guest" },
  ],

  wordVersion: [
    { id: 1, version_word: "Desktop version" },
    { id: 2, version_word: "Web version" },
    // { id: 2, version_word: "SharePoint version" },
  ],

  // wordVersion: [
  //   { id: 1, version_word: "MS Word desktop version" },
  //   { id: 2, version_word: "MS Word web version" },
  // ],
  itemsIsLoading: false,
  itemsIsFailed: false,
  dialogOpen: false,
  dialogUpdateOpen: false,
  dialogResetOpen: false,
  dialogOpenBuyCredit: false,
  dialogNewMessageOpen: false,
  // dialogOpenImpression: false,
  dialogUpdateOpenImpression: false,
  settingsMenuOpen: false,
  dialogAddContrsctOpen: false,

  dialogOpenRule: false,
  dialogOpenTesting: false,
  dialogBilling: false,

  setupHistoryArr: [],
  totalHistories: null,
  currentPage: 0,
  visPages: [], //буфер
  diapazonPages: 3, //количество видимых страниц
  pagesCount: 1, //quantity pages
  pageSize: 10, //quantity string in page

  balanceCredits: 0,
  setupCreditArr: [],
  totalCredits: null,
  currentPageCredit: 0,
  visPagesCredit: [], //буфер
  diapazonPagesCredit: 3, //количество видимых страниц
  pagesCountCredit: 1, //quantity pages
  pageSizeCredit: 10, //quantity string in page
  // confirmationDelete :false,

  setupUserArr: [],
  totalUsers: null,
  currentPageUser: 0,
  visPagesUser: [], //буфер
  diapazonPagesUser: 3, //количество видимых страниц
  pagesCountUser: 1, //quantity pages
  pageSizeUser: 10, //quantity string in page

  setupCompanyArr: [],
  totalCompanies: null,
  currentPageCompany: 0,
  visPagesCompany: [], //буфер
  diapazonPagesCompany: 3, //количество видимых страниц
  pagesCountCompany: 1, //quantity pages
  pageSizeCompany: 50, //quantity string in page

  setupImpressionArrAll: [],
  setupImpressionArr: [],
  totalImpressions: null,
  currentPageImpression: 0,
  visPagesImpression: [], //буфер
  diapazonPagesImpression: 3, //количество видимых страниц
  pagesCountImpression: 1, //quantity pages
  pageSizeImpression: 10, //quantity string in page

  setupCompanyCashflowArr: [],
  totalCompaniesCashflow: null,
  currentPageCompanyCashflow: 0,
  visPagesCompanyCashflow: [], //буфер
  diapazonPagesCompanyCashflow: 3, //количество видимых страниц
  pagesCountCompanyCashflow: 1, //quantity pages
  pageSizeCompanyCashflow: 50, //quantity string in page

  setupDisorderArr: [],
  totalDisordersflow: null,
  currentPageDisorder: 0,
  visPagesDisorder: [], //буфер
  diapazonPagesDisorder: 3, //количество видимых страниц
  pagesCountDisorder: 1, //quantity pages
  pageSizeDisorder: 50, //quantity string in page

  setupCompanySubsArr: [],
  totalCompaniesSubs: null,
  currentPageCompanySubs: 0,
  visPagesCompanySubs: [], //буфер
  diapazonPagesCompanySubs: 3, //количество видимых страниц
  pagesCountCompanySubs: 1, //quantity pages
  pageSizeCompanySubs: 50, //quantity string in page

  setupCompanyAsiSubsArr: [],
  totalCompaniesAsiSubs: 0,
  currentPageCompanyAsiSubs: 0,
  visPagesCompanyAsiSubs: [], //буфер
  diapazonPagesCompanyAsiSubs: 3, //количество видимых страниц
  pagesCountCompanyAsiSubs: 1, //quantity pages
  pageSizeCompanyAsiSubs: 50, //quantity string in page

  setupCompanyHafSubsArr: [],
  totalCompaniesHafSubs: 0,
  currentPageCompanyHafSubs: 0,
  visPagesCompanyHafSubs: [], //буфер
  diapazonPagesCompanyHafSubs: 3, //количество видимых страниц
  pagesCountCompanyHafSubs: 1, //quantity pages
  pageSizeCompanyHafSubs: 50, //quantity string in page

  setupCompanyContractArr: [],
  totalCompaniesContract: null,
  currentPageCompanyContract: 0,
  visPagesCompanyContract: [], //буфер
  diapazonPagesCompanyContract: 3, //количество видимых страниц
  pagesCountCompanyContract: 1, //quantity pages
  pageSizeCompanyContract: 50, //quantity string in page

  setupCompanyPropaArrForModal: [],
  setupCompanyPropaArr: [],
  totalCompaniesPropa: null,
  currentPageCompanyPropa: 0,
  visPagesCompanyPropa: [], //буфер
  diapazonPagesCompanyPropa: 3, //количество видимых страниц
  pagesCountCompanyPropa: 1, //quantity pages
  pageSizeCompanyPropa: 50, //quantity string in page


  setupCompanySubsNotCompletedArr: [],
  totalCompaniesSubsNotCompleted: null,
  currentPageCompanySubsNotCompleted: 0,
  visPagesCompanySubsNotCompleted: [], //буфер
  diapazonPagesCompanySubsNotCompleted: 3, //количество видимых страниц
  pagesCountCompanySubsNotCompleted: 1, //quantity pages
  pageSizeCompanySubsNotCompleted: 50, //quantity string in page

  resultCodeDisorderArr: [],
  resultTitleDisorderArr: [],

  resultCodeDisorderArrMass: [],
  resultTitleDisorderArrMass: [],
  // activeCode: "",
  // activeTiile: "",
  activeElCodeTitle: "",
  disorderMass: [],
  toolTipDisorder: "",
  idOfRecCode: {
    "SUM-PRI1": 0,
    "SUM-ADD1": 0,
    "SUM-ADD3": 0,
    "SUM-ADD5": 0,
    "SUM-ADD7": 0,
    "SUM-ADD9": 0,
    "SUM-ADD11": 0,
    "SUM-ADD13": 0,
    "SUM-ADD15": 0,
    "SUM-ADD17": 0,
    "SUM-ADD19": 0,
  },
  idOfRecTitle: {
    "SUM-PRI2": 0,
    "SUM-ADD2": 0,
    "SUM-ADD4": 0,
    "SUM-ADD6": 0,
    "SUM-ADD8": 0,
    "SUM-ADD10": 0,
    "SUM-ADD12": 0,
    "SUM-ADD14": 0,
    "SUM-ADD16": 0,
    "SUM-ADD18": 0,
    "SUM-ADD20": 0,
  },
  actForCode: [
    "SUM-PRI1",
    "SUM-ADD1",
    "SUM-ADD3",
    "SUM-ADD5",
    "SUM-ADD7",
    "SUM-ADD9",
    "SUM-ADD11",
    "SUM-ADD13",
    "SUM-ADD15",
    "SUM-ADD17",
    "SUM-ADD19",
  ],
  actForTitle: [
    "SUM-PRI2",
    "SUM-ADD2",
    "SUM-ADD4",
    "SUM-ADD6",
    "SUM-ADD8",
    "SUM-ADD10",
    "SUM-ADD12",
    "SUM-ADD14",
    "SUM-ADD16",
    "SUM-ADD18",
    "SUM-ADD10",
  ],
  errorMsg: "",

  navigation: {
    items: [
      {
        _tag: "CSidebarNavItem",
        name: "New Assessment",
        url: "/selectnewassessment",
        to: "/selectnewassessment",
        tobackup: "/selectnewassessment",
        addLinkClass: "haf-navbtn-new",
        fontIcon: "fas fa-plus",
        // attributes: { disabled: false },
        disabled: false,
      },
      {
        _tag: "CSidebarNavItem",
        name: "My Assessments",
        url: "/myassessments",
        to: "",
        tobackup: "",
        fontIcon: "fas fa-user",
        // attributes: { disabled: true },
        addLinkClass: "c-disabled",
        disabled: true,
      },
      {
        _tag: "CSidebarNavItem",
        name: "All Assessments",
        url: "/allassessments",
        to: "",
        tobackup: "",
        fontIcon: "fas fa-users",
        // attributes: { disabled: true },
        addLinkClass: "c-disabled",
        disabled: true,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Setup",
        url: "/setup",
        to: "",
        tobackup: "",
        fontIcon: "fas fa-wrench",
        // attributes: { disabled: true },
        addLinkClass: "c-disabled",
        disabled: true,
      },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: "Referral",
      //   url: "/referral",
      //   to: "/referral",
      //   tobackup: "/referral",
      //   fontIcon: "fa-solid fa-user-plus",
      //   // attributes: { disabled: false },
      //   addLinkClass: "",
      //   disabled: false,
      // },
      {
        _tag: "CSidebarNavItem",
        name: "Getting Started",
        url: "/gettingstarted",
        to: "/gettingstarted",
        tobackup: "/gettingstarted",
        fontIcon: "icon-question",
        // attributes: { disabled: false },
        addLinkClass: "",
        disabled: false,
      },
    ],
  },
  newAssessmentMode: "Start",
  newAssessment: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    assessment: "",
    assessmentOrig: "",
    firstname: "",
    middlename: "",
    lastname: "",
    gender: "",
    sex: "",
    gender_explain: "",
    marriage: "",
    email_client: "",
    email_client_onblur: "",
    email_client_repeat: "",
    email_client_repeat_onblur: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: "United States (+1)",
    county: "",
    evaluator: "",
    degree_prof_credential: "",
    phone: "",
    ssn: "",
    dob: "",
    created: "",
    updated: "",
    packed: "",
    provider: "",
    status: "",
    adult_type: "",
    atype: "",
    allow_support: 0,
    print_status: "",
    verifycode: "",
    remotestatus: "",
    version: "",
    version_update: "",
    payment_method: "",

    // email: ""
  },
  assessmentPage: 1,

  myAssessmentArr: [],
  totalMyAssessments: null,
  newMyAssessments: null,
  notcompletedMyAssessments: null,
  completedMyAssessments: null,
  currentPageMyAssessment: 0,
  visPagesMyAssessments: [0, 1, 2], //буфер
  diapazonPagesMyAssessment: 3, //количество видимых страниц
  pagesCountMyAssessment: 1, //quantity pages
  pageSizeMyAssessment: 45, //quantity string in page

  allMessagesArr: [],
  countNewMsg: 0,
  // assessmentQuest: {
  //   pp1: "123",
  //   changed_pp: false
  // },
  assessment_active_tab: "Demographic Information.",
  // assessment_state: assessmentPattern,
  assessment_state: [],
  assessment_state_changed: false,
  searchAssessment: "",
  fieldAssessment: "lastname",
  initial_assessment_tab: {},
  undo_assessment_tab: {},
  dangerElem: [],
  dangerTab: [],
  nonDangerTab: [],
  dangerObjTab: {},
  nonDangerObjTab: {},
  ruleElem: [],
  transformruleElem: [],
  blockTransaction: false,
  loginDialogOpen: false,
  loginMessage: "",
  assessment_confirm: false,
  startassessment_confirm: false,
  hasError: false,
  assessmentReadyStatus: Constants.ASSESSMENT_NEW,
  previewConfirm: false,
  previewConfirmTp: false,
  previewConfirmAsi: false,
  previewConfirmObjAsi: { isOpen: false, content: "" },
  previewConfirmCommon: false,
  htmlPreview: "",
  htmlPreviewAsi: "",
  modalAssessmentList: false,
  modalAssessmentListOrig: false,
  modalRemoteSession: false,
  modalAssessmentObj: {},
  needRefreshAssessmentList: false,
  needRefreshAssessmentListAndFirstPage: false,
  needRefreshSubscriptions: false,
  confimYesNo: false,
  confimYesNoCloseMyAccount: false,
  haf_or_asi: "ASI",
  confimYesNoUser: false,
  confirmYesNoNewAssessment: false,
  // confirmYesNoNewAssessment2 : false,
  confirmYesNoNewAssessment2: {
    status: false,
    buttonPath: "SaveContinueNow",
  },
  confimYesNoFile: false,
  confimYesNoSubscription: false,
  confimYesNoRecommedation: false,
  clearundo: "",
  needRefresUserList: false,
  needRefresCreditList: false,
  needRefreshCreditListAndFirstPage: false,
  needRefresHistoryList: false,
  needRefresImpressionList: false,
  assessmentFilter: {
    name: "Assessment Status :",
    prevalue: "Assessment Status :",
    disabled: "false",
    type: "checkbox",
    value: [true, true, true],
    radio_options: ["New", "Not Completed", "Completed"],
    tglActiveCls: [
      "haf-badge-status-new",
      "haf-badge-status-not-completed",
      "haf-badge-status-completed",
    ],
    rectangleCls: [
      "rectangle-new ",
      "rectangle-not-completed ",
      "rectangle-completed ",
    ],
  },
  selectedPeriodFilter: "all",
  wasClick: 0,
  hasErrorToken: { error: false, msg: "" },
  migrationMassive: [],
  patient_info_changed: false,
  modalUserObj: {},
  confimPayment: false,
  agreeTermOfService: false,
  choosedAssessmentId: 0,
  QuantitySubstances: 0,
  RankStatus: 0,
  setupOrgBackup: {},
  setupUserBackup: {},
  tryLoginInactive: false,

  fieldCreditManagement: "Organization",
  searchCreditManagement: "",
  selectedPeriodFilterCreditManagement: "all",
  needRefreshCreditManagementList: false,
  needRefreshCreditManagementListAndFirstPage: false,

  fieldCashflowManagement: "Organization",
  searchCashflowManagement: "",
  selectedPeriodFilterCashflowManagement: "all",
  needRefreshCashflowManagementList: false,
  needRefreshCashflowManagementListAndFirstPage: false,

  fieldSubscriptionManagement: "Organization",
  searchSubscriptionManagement: "",
  selectedPeriodFilterSubscriptionManagement: "all",
  needRefreshSubscriptionManagementList: false,
  needRefreshSubscriptionManagementListAndFirstPage: false,

  fieldContractManagement: "Organization",
  searchContractManagement: "",
  selectedPeriodFilterContractManagement: "all",
  needRefreshContractManagementList: false,
  needRefreshContractManagementListAndFirstPage: false,

  fieldPropaManagement: "Organization",
  searchPropaManagement: "",
  selectedPeriodFilterPropaManagement: "all",
  needRefreshPropaManagementList: false,
  needRefreshPropaManagementListAndFirstPage: false,

  fieldSubscriptionManagementNotCompleted: "org",
  searchSubscriptionManagementNotCompleted: "",
  selectedPeriodFilterSubscriptionManagementNotCompleted: "all",
  needRefreshSubscriptionManagementNotCompletedList: false,
  needRefreshSubscriptionManagementListNotCompletedAndFirstPage: false,

  fieldHistories: "Event",
  searchHistories: "",
  searchHistoriesEmailAndId: "*",
  searchHistoriesFrom: "",
  searchHistoriesTo: "",
  needRefreshHistoriesList: false,
  needRefreshHistoriesListAndFirstPage: false,
  /*
  eventType: [
    { id: 0, title: "All", value: "" },
    { id: 1, title: "Successful login", value: "Successful login" },
    { id: 2, title: "Unsuccessful login", value: "Unsuccessful login" },
    { id: 3, title: "Payment credit", value: "Payment credit" },
    { id: 4, title: "Payment canceled", value: "Payment canceled" },
    { id: 5, title: "Deleted Recommendation", value: "Deleted Recommendation" },
    { id: 6, title: "Registration new Recommendation", value: "Registration new Recommendation" },
    { id: 7, title: "Update Recommendation", value: "Update Recommendation" },
    { id: 8, title: "Assessment form was updated", value: "has been saved. Evaluator:" },
    { id: 9, title: "Assessment form was created", value: "has been added to the system" },
    { id: 10, title: "Generate narrative", value: "generate narrative" },
    { id: 11, title: "Free narrative printing", value: "Free narrative printing" },
    { id: 12, title: "Deleted Assessment", value: "Deleted Assessment" },
    { id: 13, title: "Registration Company", value: "Registration Company" },
    { id: 14, title: "Updated Info Company", value: "Updated Info Company" },
    { id: 15, title: "Discard chancheges Info Company", value: "Discard chancheges Info Company" },
    { id: 16, title: "Registration new user", value: "Registration new user" },
    { id: 17, title: "Updated user", value: "Updated user" },
    { id: 18, title: "Reset user", value: "Reset user" },
    { id: 19, title: "Changed role", value: "role " },
    { id: 20, title: "Changed status", value: "status" },
    { id: 21, title: "Deleted user", value: "Deleted user" },
  ],
  */
  eventType: [
    { id: 0, title: "All", value: "" },
    {
      id: 1,
      title: "Assessment form was created",
      value: "has been added to the system",
    },
    {
      id: 2,
      title: "Assessment form was updated",
      value: "has been saved. Evaluator:",
    },
    { id: 3, title: "Changed role", value: "role " },
    { id: 4, title: "Changed status", value: "status" },
    { id: 5, title: "Deleted Assessment", value: "Deleted Assessment" },
    { id: 6, title: "Deleted Recommendation", value: "Deleted Recommendation" },
    { id: 7, title: "Deleted Rule", value: "Deleted Rule" },
    { id: 8, title: "Deleted user", value: "Deleted user" },
    {
      id: 9,
      title: "Discard chancheges Info Company",
      value: "Discard chancheges Info Company",
    },
    {
      id: 10,
      title: "Free narrative printing",
      value: "Free narrative printing",
    },
    { id: 11, title: "Generate narrative", value: "generate narrative" },
    { id: 12, title: "Notification", value: "Notification" },
    { id: 13, title: "Payment canceled", value: "Payment canceled" },
    { id: 14, title: "Payment credit", value: "Payment credit" },
    { id: 15, title: "Registration Company", value: "Registration Company" },
    {
      id: 16,
      title: "Registration new Promo",
      value: "Registration new Promo",
    },
    {
      id: 17,
      title: "Registration new Recommendation",
      value: "Registration new Recommendation",
    },
    {
      id: 18,
      title: "Registration new Rule",
      value: "Registration new Rule",
    },
    { id: 19, title: "Registration new user", value: "Registration new user" },
    { id: 20, title: "Reset user", value: "Reset user" },
    { id: 21, title: "Successful login", value: "Successful login" },
    { id: 22, title: "Unsuccessful login", value: "Unsuccessful login" },
    { id: 23, title: "Update Promo", value: "Update Promo" },
    { id: 24, title: "Update Recommendation", value: "Update Recommendation" },
    { id: 25, title: "Update Rule", value: "Update Rule" },
    { id: 26, title: "Updated Info Company", value: "Updated Info Company" },
    { id: 27, title: "Updated user", value: "Updated user" },
  ],

  eventEmailAndId: [],
  arrayLabels: [],
  search_options: [
    { title: "Assessment #", value: "assessment" },
    { title: "First name", value: "firstname" },
    { title: "Last name", value: "lastname" },
    { title: "Gender", value: "gender" },
    { title: "Marital Status", value: "marriage" },
    { title: "Street", value: "street" },
    { title: "City", value: "city" },
    { title: "State", value: "state" },
    { title: "Zip Code", value: "zip_code" },
    { title: "Evaluator", value: "evaluator" },
    { title: "Phone", value: "phone" },
    { title: "SSN", value: "ssn" },
    // { title: "Adult Type", value: "adult_type" },
    { title: "Type", value: "atype" },
    // { title: "Version", value: "version" },
    { title: "Provider", value: "provider" },
  ],
  changeTypeAssessment: false,
  changeTypeAssessmentAsi: false,
  sidebarShow: "responsive", //"responsive"
  organizationForDelete: { CompanyId: 0, organization: "" },
  setupActiveTab: "Organization",
  cashflowSortParam: [
    {
      param_sort: "created", //OK
      param_up_down: "High to Low",
    },
    {
      param_sort: "balance", //OK
      param_up_down: "",
    },
    {
      param_sort: "was_spent_from_created",
      param_up_down: "",
    },
    {
      param_sort: "days_from_created",
      param_up_down: "",
    },
    {
      param_sort: "average_spent_from_created", //OK
      param_up_down: "",
    },
    {
      param_sort: "was_spent_from_period",
      param_up_down: "",
    },
    {
      param_sort: "days_from_period",
      param_up_down: "",
    },
    {
      param_sort: "average_spent_from_period", //OK
      param_up_down: "",
    },
    {
      param_sort: "projected_day_to_buy", //OK
      param_up_down: "",
    },
    {
      param_sort: "was_bought_from_created", //OK
      param_up_down: "",
    },
    {
      param_sort: "average_bought_from_created",
      param_up_down: "",
    },
    {
      param_sort: "was_bought_from_period", //OK
      param_up_down: "",
    },
    {
      param_sort: "average_bought_from_period",
      param_up_down: "",
    },
  ],
  modalNotification: false,

  paramNotification: {
    type: "",
    CompanyId: 0,
    from: "noreply@herdmanhealth.com",
    to: "",
    subject: "",
    // title: "",
    message: "",
  },

  creditsFilter: {
    name: "Credits Status :",
    prevalue: "Credits Status :",
    disabled: "false",
    type: "checkbox",
    value: [true, true],
    radio_options: ["Credits Used", "Credits Received"],
    tglActiveCls: ["haf-badge-expense", "haf-badge-receipts"],
  },

  // findMonthPerf :"2021-03",
  // findYearPerf : "2020",
  findMonthPerf: "",
  perfMonthArr: [],
  findYearPerf: "",
  perfYearArr: [],

  /********************RULE */
  ruleSection: "DEMO",
  ruleId: { id: "", shortname: "" },
  ruleResult: "",
  ruleResultPos: 0,
  ruleName: "",
  newRulePredicate: {
    idKey: "",
    id: "",
    shortname: "",
    title: "",
    type: "",
    scopeOperations: [],
    operation: "",
    scopeValues: [],
    value: "",
  },
  newRule: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    created: "",
    updated: "",
    rule_window: "",
    rule_body: "",
    result: "",
    rulename: "",
    subs: 1,
    mh: 1,
    cooc: 1,
    adult_type: "Adult",
    priority: 0,
    checked: 0,
  },
  newRulePredicateMas: [],
  ruleListArr: [],
  totalRules: null,
  currentPageRule: 0,
  visPagesRule: [], //буфер
  diapazonPagesRule: 3, //количество видимых страниц
  pagesCountRule: 1, //quantity pages
  pageSizeRule: 20, //quantity string in page
  needRefresRuleList: false,
  controlDialog: [], //"New"/"Update"/"Testing"
  rulePlaceholder: "",

  assessmentTypeFilter: {
    name: "Assessment Type :",
    prevalue: "Assessment Type :",
    disabled: "false",
    type: "checkbox",
    value: [true, true, true],
    radio_options: ["SUBS", "MH", "COOC"],
  },
  ruleCheckedId: [], //array of checked ID
  ruleCheckedIdName: [], //array of checked ID
  enableMoveRule: 0,
  ruleAssessment: "",
  clientInfoContent: [
    // {id: 1, key:"assessment", title : "<b>Assessment:</b>", content: ""},
    // {id: 2, key:"firstname", title : "<b>Firstname:</b>", content: ""},
    // {id: 3, key:"middlename", title : "<b>Middlename:</b>", content: ""},
    // {id: 4, key:"lastname", title : "<b>Lastname:</b>", content: ""},
    // {id: 5, key:"gender", title : "<b>Gender:</b>", content: ""},
    // {id: 6, key:"atype", title : "<b>Type:</b>", content: ""},
    // {id: 7, key:"status", title : "<b>Status:</b>", content: ""},
  ],
  resultContent: "",
  eventRule: "delete", //delete move
  refRuleEditor: null,
  makeAnimationStatus: false,
  needRefresQuillPosition: false,
  // rulepromoActiveTab: "Recommendations Adult",
  rulepromoActiveTab: "Recommendations",
  cashflowActiveTab: "Payment Performance",
  ruleAdultType: "All",
  assessmentAdultType: "All",
  csManagementActiveTab: "Credit Management",
  //********************************** */ transformrule
  transformruleSectionLeft: "GI",
  transformruleSectionRight: "DEMO",
  transformruleIdLeft: { id: "", shortname: "" },
  transformruleIdRight: { id: "", shortname: "" },
  transformruleResult: "",
  transformruleResultPos: 0,
  transformruleName: "",
  newTransformrulePredicate: {
    idKey: "",
    id: "",
    shortname: "",
    title: "",
    type: "",
    scopeOperations: [],
    operation: "",
    scopeValues: [],
    value: "",
  },
  newTransformrulePredicateRight: {
    idKey: "",
    id: "",
    shortname: "",
    title: "",
    type: "",
    scopeOperations: [],
    operation: "",
    scopeValues: [],
    value: "",
  },
  newTransformrulePredicateLeft: {
    idKey: "",
    id: "",
    shortname: "",
    title: "",
    type: "",
    scopeOperations: [],
    operation: "",
    scopeValues: [],
    value: "",
  },
  newTransformrule: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    created: "",
    updated: "",
    rule_window: "",
    rule_body: "",
    result: "",
    rulename: "",
    subs: 1,
    mh: 1,
    cooc: 1,
    adult_type: "ASI",
    priority: 0,
    checked: 0,
  },
  newTransformrulePredicateMas: [],
  newTransformrulePredicateMasLeft: [],
  newTransformrulePredicateMasRight: [],
  transformruleTable: [],
  transformruleListArr: [],
  totalTransformrules: null,
  currentPageTransformrule: 0,
  visPagesTransformrule: [], //буфер
  diapazonPagesTransformrule: 3, //количество видимых страниц
  pagesCountTransformrule: 1, //quantity pages
  pageSizeTransformrule: 20, //quantity string in page
  needRefresTransformruleList: false,
  // controlDialog: [], //"New"/"Update"/"Testing"
  transformrulePlaceholder: "",

  // assessmentTypeFilter: {
  //   name: "Assessment Type :",
  //   prevalue: "Assessment Type :",
  //   disabled: "false",
  //   type: "checkbox",
  //   value: [true, true, true],
  //   radio_options: ["SUBS", "MH", "COOC"],
  // },
  transformruleCheckedId: [], //array of checked ID
  transformruleCheckedIdName: [], //array of checked ID
  enableMoveTransformrule: 0,
  transformruleAssessment: "",
  // clientInfoContent: [
  // ],
  // resultContent: "",
  eventTransformrule: "delete", //delete move
  refTransformruleEditor: null,
  // makeAnimationStatus: false,
  // needRefresQuillPosition: false,
  transformrulepromoActiveTab: "Transformation Rules",
  // transformrulepromoActiveTab: "Recommendations",
  transformruleAdultType: "All",
  // assessmentAdultType : "All",

  /************************************ */

  newPromo: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    description: "",
    code: "",
    bonus: 0,
    created: "",
    expires: "",
    status: "",
  },
  controlPromoDialog: [], //"New"/"Update"/"Testing"
  dialogOpenPromo: false,
  promoListArr: [],
  totalPromos: null,
  currentPagePromo: 0,
  visPagesPromo: [], //буфер
  diapazonPagesPromo: 3, //количество видимых страниц
  pagesCountPromo: 1, //quantity pages
  pageSizePromo: 20, //quantity string in page
  needRefresPromoList: false,
  confimYesNoPromo: false,

  promoExpiresFilter: {
    name: "Unlimited :",
    prevalue: "Unlimited :",
    disabled: "false",
    type: "checkbox",
    value: [false],
    radio_options: ["Unlimited"],
  },
  promoRegistr: "",
  promoSetup: { id: "0", code: "" },
  findMonthPromoPerf: "*", //2000 //All
  findYearPromoPerf: "*",
  cashflowPromo: "*",
  creditManagementPromo: "*",
  subscriptionManagementPromo: "*",
  contractManagementPromo: "*",
  propaManagementPromo: "*",
  assessmentPromo: "*",

  controlImpressionDialog: [], //"New"/"Update"/"Testing"
  dialogOpenImpression: false,
  impressionResult: "",
  impressionResultPos: 0,
  impressionPlaceholder: "",
  showButtonChange: false,

  adultType: [
    { id: 1, name: "All" },
    { id: 2, name: "Adult" },
    { id: 3, name: "Adolescent" },
    { id: 4, name: "ASI" },
  ],

  activationInfo: {
    email: "",
    hashCode: "",
    password: "",
  },

  showPassword: {
    name: "Show Password :",
    prevalue: "Show Password :",
    disabled: "false",
    type: "checkbox",
    value: [false],
    radio_options: [""],
    tglActiveCls: ["haf-badge-expense"],
  },
  passwordForReset: {
    password: "",
    confirm: "",
  },
  variantChangingPassword: [
    { id: 1, method: "By Email" },
    { id: 2, method: "Direct input" },
    // { id: 2, version_word: "SharePoint version" },
  ],
  methodChangingOfPassword: "By Email",

  historyConfirm: false,
  progressUpgrade: 0,

  titleTour: false,
  titleTourStartOnMyAssessment: false,
  getStartedTour: false,
  steps1: steps1,
  steps2: steps2,
  steps3: steps3,
  steps4: steps4,
  newAssessmentStepOfTour: false,

  degreeList: [
    { id: 0, title: "", value: "" },
    { id: 1, title: "DDiv", value: "Doctor of Divinity" },
    { id: 2, title: "DMin", value: "Doctor of Ministry" },
    { id: 3, title: "DNP", value: "Doctor of Nursing Practice" },
  ],

  profCredentialList: [
    { id: 0, title: "", value: "" },
    {
      id: 1,
      title: "ABCFP",
      value: "American Board of Couple and Family Psychology",
    },
    {
      id: 2,
      title: "ABPN",
      value: "American Board of Professional Neuropsychology",
    },
    {
      id: 3,
      title: "ABPP",
      value: "American Board of Professional Psychology",
    },
  ],

  ruleAssessmentJson: adultPattern,
  transformruleAssessmentJsonLeft: ASIPattern,
  transformruleAssessmentJson: adultPattern,

  minimizeCSidebar: false,
  loadedDefaultImpressions: false,
  showLeftSections: true,
  dropdownPreviewOpen: false,
  modalProgress: false,
  modalProgressIndex: 0,
  pageSelection: 0,
  userSubscriptionsArr: [],
  modalReturnToHaf: false,
  changeOwnerSubscriptionArr: [],
  showCompany: false,
  choosedForShowCompany: null,
  refreshTablePerfMonthArrORspinner: false,
  refreshTablePerfYearArrORspinner: false,
  refreshTablesStupCompanyCashflowArrORspinner: false,
  cashflowActiveTab: "Payment Performance",
  jsonForModalPreview: [],
  arrayOfSectoonsForPreview: [],
  currentSectoonForPreview: {},
  previewActiveTab: "Personal Information",
  confimYesNoPayment: false,
  // confimYesNoPaymentMsg : "",
  // continuePaymentProcess: false,
  modalReferral: false,
  whatReferralPayment: Constants.PAYMENT_REFERRAL_CREDITS,
  // useModalOrStripe : "stripe", //modal
  modalStartStripe: false,

  newProduct: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    stripe_price_id: "",
    product_date: "",
    product: "",
    credit: 0,
    cost: 0.0,
    type_system: "HAF",
    type_subscription: "Limited",
    status: "",
  },

  controlProductDialog: [], //"New"/"Update"/"Testing"
  dialogOpenProduct: false,
  productListArr: [],
  totalProducts: null,
  currentPageProduct: 0,
  visPagesProduct: [], //буфер
  diapazonPagesProduct: 3, //количество видимых страниц
  pagesCountProduct: 1, //quantity pages
  pageSizeProduct: 20, //quantity string in page
  needRefresProductList: false,
  confimYesNoProduct: false,

  type_system_option: {
    name: "Assessment Type :",
    prevalue: "Assessment Type :",
    disabled: "false",
    type: "checkbox",
    value: [true, true, true],
    radio_options: ["SUBS", "MH", "COOC"],
  },

  type_system: [
    { id: 1, name: "HAF" /*, status: false*/ },
    { id: 2, name: "ASI" /*,status: false */ },
  ],
  type_subscription: [
    { id: 1, name: "Limited" },
    { id: 2, name: "Unlimited" },
  ],
  payment_term_options: [
    { id: 1, name: "" },
    { id: 2, name: "Once at the beginning of the contract" },
    { id: 3, name: "Once a month" },
    { id: 4, name: "Once a quarter" },
    { id: 5, name: "Once a year" },
    { id: 6, name: "Undefined" },
  ],

  status_contract_options: [
    { id: 1, name: "" },
    { id: 2, name: "Active" },
    { id: 3, name: "Hold On" },
    { id: 4, name: "Dismiss" },
  ],
  choosed_stripe_price_id: "",
  colorSubscribe: "",
  colorCredit: "",

  newContract: {
    id: 0,
    CompanyId: 0,
    UserId: 0,
    CompanyContractId: 0,
    contract_id: "",
    contract_description: "",
    organization: "",
    date_of_start_contract: "",
    date_of_end_contract: "",
    date_of_last_invoice: "",
    amount_of_last_invoice: "",
    payment_term: "",
    credits: "",
    amount: "",
    // type_system: "HAF",
    produuct_line_haf: { name: "HAF", status: false },
    produuct_line_asi: { name: "ASI", status: false },
    status_contract: "",
  },

  controlContractDialog: [],
  dialogOpenContract: false,
  organizationArray: [],
  contractResult: "",
  contractResultPos: 0,
  contractPlaceholder: "",

  userContractsArr: [],
  openPaymentHistory: false,
  setupBillingArr: [],

  smsFilter: {
    name: "SMS Status :",
    prevalue: "SMS Status :",
    disabled: "false",
    type: "checkbox",
    value: [true, true],
    radio_options: ["SMS Yes", "SMS No"],
    tglActiveCls: ["haf-sms-yes", "haf-sms-no"],
  },

  treatmentplanPreSet: "",
  treatmentplanPreSetPos: 0,

  treatmentplanPlaceholder: "",

  treatmentplanPostSet: "",
  treatmentplanPostSetPos: 0,

  sourceFileToUpload: "",
  arrayOfFiles: [],
  arrayOfFiles1: [],
  needRefresListOfFiles: false,
  fileForDelete: "",
  problemListArr: [],
  problemListValue: "",
  // treeData: [{ title: '',
  //              subtitle: "",
  //              children:
  //              [{title: '',
  //              subtitle: "",
  //              children:
  //              [{title: '',
  //              subtitle: "",
  //               children:[
  //              ] }] }]}],
  // treeData: [], ///-
  testTitle: "",

  confimField: false,
  goalObjectiveField: "",
  goal_OR_objective: "goal",
  add_OR_edit: "add",
  rowInfo: {},
  confimYesNoNode: false,
  defaultSetupActiveTab: "Default Recommendations",
  defaultSetupUser: { UserId: 0, CompanyId: 0, email: "" },
  newRepository: {
    id: 0,
    CompanyId: 0,
    preset: "",
    postset: "",
    treeData: [],
    presetORpostsetChanged: false,
  },
  // preset: "", ///-
  // postset: "", ///-
  // presetORpostsetChanged: false, ///-

  confirmSelectCompany: false,
  selectedCompany: { CompanyId: 0, organization: "" }, // propogation modal Add Company
  searchSelectCompany : "",

  allowTreatmentPlanForCompany: false,
  setupCompanyArrForImpression: [],
  openLeftWindowAdvisor: false,
  outputAdvisorAdvice: "",
  // confimRecreateAi: false,
  wasOldOutputAdvisorAdvice: false,
  trialModeStripe: {status: false, period: 0, quantity:1 },
  countOfHafCombined: {error: false,msg: 0},
  typeOfHafCombined: "",



  // checkReferrals : false
  /*
  steps1: [
    // {
    //   content: "Are you ready for a tour?",
    //   target: "body",
    //   placement: "center",
    // },
    {
      target: ".my-assessments",
      content: "Click on the hyperlink of the sample assessment.",
      disableBeacon : true,
      event : "hover"
      // placement : "top"
      // placement: "top",
    },
  ],

  steps2: [
    {
      target: ".my-chapters-panel",
      content: "The assessment is broken down into multiple chapters.",
      disableBeacon : true,
      event : "click"
      // placement : "top"
      // placement: "top",
    },
    {
      target: ".my-send-to-patient",
      content:
        "You can email an assessment to your patient to be completed by them online",
        disableBeacon : true,
        // placement : "top"
      // placement: "top",
      // locale: { skip: 'S-K-I-P' },
    },
    {
      target: ".my-diag-summary-recom",
      content:
        "Click on the Diagnosis/Summary/Recommendations chapter to complete the assessment",
        disableBeacon : true,
        // placement : "top"
      // placement: "top",
    },
    {
      target: ".my-preview-narrative-button",
      content:
        "A completed assessment can be converted to a Microsoft Word document",
        disableBeacon : true,
        // placement : "top"
      // placement: "top",
    },
  ],
  */
  // runtour: true
};

export default initialState;

// (These lines will be logged by the middleware:)
// will dispatch: { type: 'ADD_TODO', text: 'Understand the middleware' }
// state after dispatch: [ 'Use Redux', 'Understand the middleware' ]
